@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans|Open+Sans|Raleway');

/* IMPORTED FONTS */
/* font-family: 'Raleway', sans-serif;
font-family: 'IBM Plex Sans', sans-serif;
font-family: 'Open Sans', sans-serif; */

/* MAJOR ELEMENTS LAYOUT */

body {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0;
  padding: 0;
  color: rgba(30, 30, 30, 1);
}

header {
  min-height: 100vh;
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  width: 85%;
  margin: 0 auto;
}

footer {
  width: 85%;
  margin: 0 auto 20px auto;
  font-size: 32px;
}

/* SHARED STYLINGS */
a {
  padding: 5px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p, li {
  font-size: 19px;
}

.mono {
  font-size: 18px;
}

.text {
  background-color: rgba(255,255,252,.9);
  padding: 5px;
}

/* CANVAS STYLING */
#myCanvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}

/* NAV */
.nav-container {
  width: 100%;
  height: 9vh;
  position: fixed;
  color: inherit;
  z-index: 99;
  background: rgba(250, 240, 244, 0.9);
}

nav {
  width: 85%;
  height: inherit;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav a {
  margin: 0 5px;
  font-size: 28px;
}

nav #home-nav {
  margin-left: 0 !important;
}

nav a:hover {
  text-decoration: underline;
  color: rgba(120, 100, 100, 1);
}

/* SPACER */
.spacer {
  height: 25vh;
  width: 100%;
}

/* WELCOME PAGE TEXT */
.text-holder {
  margin: 0 auto;
}

.intro {
  z-index: 1;
  opacity: 1;
  width: 100%;
  text-align: left;
  color: rgba(45, 45, 45, 1);
}

h1.hello {
  font-size: 60px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: 'Raleway', sans-serif;
}

h1 {
  font-size: 44px;
  margin-top: 0;
  font-family: 'Raleway', sans-serif;
}

.intro h2 {
  margin-bottom: 30px;
}

.contact-icons {
  text-align: left;
  margin-top: 5%;
}

.contact-icons a {
  padding: 0;
  margin-right: 10px;
  font-size: 48px;
  background-color: white;
  color: rgba(40, 40, 40, 1);
}

.contact-icons a:hover, .scroll-down a:hover {
  color: rgba(120, 100, 100, 1);
}

.scroll-down {
  margin: 50px auto 0 auto;
  font-size: 36px;
  text-align: center;
}

/* ABOUT */
.about {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.about-spacer, .projects-spacer {
  width: 100%;
  height: 10vh;
}

.about h1 {
  margin-bottom: 10px;
}

.about p {
  margin-bottom: 0
}

p.one {
  margin-top: 0 !important;
}

.about-info {
  margin-bottom: 20px;
}

.skills, .learning {
  font-family: monospace;
  margin-bottom: 10px;
}

.skills-head, .learning-head {
  position: relative;
}
.skills-head h3, .learning-head h3 {
  margin: 0;
}

.skills-body div, .learning-body div {
  display: inline-block;
  border: 1px solid black;
  background-color: rgba(255,255,252,.9);
  padding: 5px;
  margin: 5px 5px 5px 0;
}

.skills-body div:hover, .learning-body div:hover {
  background-color: rgb(121, 139, 234);
}

/* PROJECTS */
.projects {
  margin: 0 auto;
}

.projects h1 {
  text-align: left;
}

.project  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 120px 0 0 0;
}

.project.playways {
  margin-top: 20px !important;
}

figure {
  width: 290px;
  height: 290px;
  border: 4px solid rgba(40, 40, 40, 1);
  position: relative;
  box-shadow: 0 0 25px rgba(0,0,0,0.8);
  margin: 0;
  margin-bottom: 20px;
}

figure img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

figcaption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* makes it disappear on normal state*/
  opacity: 0;
  /* smooths hover */
  transition: all .25s ease-in-out;
  /* styling figcaption */
  background-color: #000000;
  color: #ffffff;
  text-align: center;
}

figcaption:hover {
  /* turns the caption back on */
  opacity: 0.7;
}

figcaption p {
  margin-top: 40%;
  font-size: 22px;
}

.circle, .circle img, .circle figcaption {
  border-radius: 50%;
}

.desc-text {
  background-color: rgba(255,255,252,.9);
  padding: 15px;
}

.description {
  width: 310px;
}

.description h2 {
  margin-top: 0;
  text-align: center;
}

.description p {
  margin-bottom: 0;
}

li {
  margin-top: 8px;
}

.desc-button {
  margin: 10px auto;
}

button.code {
  display: block;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px;
  background-color: #f2e1e1;
  border: 1px solid rgba(30, 30, 30, 1);
  font-family: monospace;
  box-shadow: 0,0,10px, rgba(0,0,0,0.5);
}

/* FOOTER */
footer {
  margin: 40px auto 20px auto;
}

div.final {
  text-align: center;
}

footer p {
  margin-bottom: 0;
  font-size: 36px;
}

footer a {
  margin: 0 5px;
  font-size: 24px;
  font-family: monospace;
  text-transform: uppercase;
}

/* RESPONSIVENESS */
@media (max-width: 350px) {
  nav a {
    font-size: 25px;
  }

  .spacer {
    height: 15vh;
  }
}

@media (min-width: 550px) {
  .description {
    width: 400px;
  }
}
@media (min-width: 630px) {
  nav {
    justify-content: flex-start;
  }
}

@media (min-width: 800px) {
  header {
    width: 80%;
  }
  nav {
    width: 80%;
  }
  .main {
    width: 80%;
  }
  .project {
    flex-direction: row;
  }
  .description {
    width: 300px;
    margin-left: 30px;
  }
}

@media (min-width: 915px) {
  .description {
    width: 400px;
  }
}

@media (min-width: 1000px) {
  p {
    font-size: 19px;
  }
  h1.hello {
    font-size: 76px;
  }
  h1 {
    font-size: 46px;
  }
}

@media (min-width: 1020px) {
  .description {
    width: 450px;
  }
}


@media (min-width: 1200px) {
  header {
    width: 75%;
  }
  nav {
    width: 75%;
  }
  .main {
    width: 75%;
  }
  nav a {
    font-size: 32px;
  }
  p {
    font-size: 21px;
  }
  h1.hello {
    font-size: 80px;
  }
  h1 {
    font-size: 48px;
  }
  .mono {
    font-size: 20px;
  }

}
